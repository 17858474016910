var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[(
      (_vm.getUserPermission.includes('oven_report') &&
        !_vm.currentUser.u.is_admin) ||
      _vm.currentUser.u.is_admin
    )?_c('router-link',{attrs:{"to":{
      name: 'client.dailyovenreports',
    }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon las la-calendar"}),_c('span',{staticClass:"menu-text",staticStyle:{"color":"black"}},[_vm._v("Daily Oven Report ")])])])]}}],null,false,1307350683)}):_vm._e(),(
      (_vm.getUserPermission.includes('mixing_report') &&
        !_vm.currentUser.u.is_admin) ||
      _vm.currentUser.u.is_admin
    )?_c('router-link',{attrs:{"to":{
      name: 'client.dailymixing',
    }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon las la-mortar-pestle"}),_c('span',{staticClass:"menu-text",staticStyle:{"color":"black"}},[_vm._v("Daily Mixing Report ")])])])]}}],null,false,793065926)}):_vm._e(),(
      (_vm.getUserPermission.includes('visitor_log') &&
        !_vm.currentUser.u.is_admin) ||
      _vm.currentUser.u.is_admin
    )?_c('router-link',{attrs:{"to":{
      name: 'client.visitorlogs',
    }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon las la-users"}),_c('span',{staticClass:"menu-text",staticStyle:{"color":"black"}},[_vm._v("Visitor Logs ")])])])]}}],null,false,3910644085)}):_vm._e(),(
      ((_vm.getUserPermission.includes('packing_material') ||
        _vm.getUserPermission.includes('finished_goods') ||
        _vm.getUserPermission.includes('raw_materials')) &&
        !_vm.currentUser.u.is_admin) ||
      _vm.currentUser.u.is_admin
    )?_c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},[_c('div',{staticClass:"mb-1",attrs:{"no-body":""}},[_c('div',{attrs:{"header-tag":"header","role":"tab"}},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-1",modifiers:{"accordion-1":true}}],staticClass:"menu-item p-5 mb-1",staticStyle:{"background":"rgb(188, 222, 251, 0.5)"}},[_vm._m(0)])]),_c('b-collapse',{attrs:{"id":"accordion-1","accordion":"my-accordion","role":"tabpanel"}},[(
            (_vm.getUserPermission.includes('raw_material') &&
              !_vm.currentUser.u.is_admin) ||
            _vm.currentUser.u.is_admin
          )?_c('router-link',{attrs:{"to":{ name: 'client.qualitycontrol.rawmaterials' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"las la-luggage-cart menu-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-8"},[_vm._v("Raw materials")])])])]}}],null,false,3409961248)}):_vm._e(),(
            (_vm.getUserPermission.includes('packing_material') &&
              !_vm.currentUser.u.is_admin) ||
            _vm.currentUser.u.is_admin
          )?_c('router-link',{attrs:{"to":{ name: 'client.qualitycontrol.packingmaterials' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"las la-box-open menu-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-8"},[_vm._v("Packing materials")])])])]}}],null,false,798000002)}):_vm._e(),(
            (_vm.getUserPermission.includes('finished_goods') &&
              !_vm.currentUser.u.is_admin) ||
            _vm.currentUser.u.is_admin
          )?_c('router-link',{attrs:{"to":{ name: 'client.qualitycontrol.finishedgoods' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"las la-tasks menu-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-8"},[_vm._v("Finished Goods")])])])]}}],null,false,844060467)}):_vm._e()],1)],1)]):_vm._e(),_c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},[_c('div',{staticClass:"mb-1",attrs:{"no-body":""}},[_c('div',{attrs:{"header-tag":"header","role":"tab"}},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-2",modifiers:{"accordion-2":true}}],staticClass:"menu-item p-5 mb-1",staticStyle:{"background":"rgb(188, 222, 251, 0.5)"}},[_vm._m(1)])]),_c('b-collapse',{attrs:{"id":"accordion-2","accordion":"my-accordion","role":"tabpanel"}},[(_vm.currentUser.u.is_admin)?_c('router-link',{attrs:{"to":{ name: 'client.biscuits' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"las la-cookie menu-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-8"},[_vm._v("Biscuits")])])])]}}],null,false,3953745803)}):_vm._e(),(_vm.currentUser.u.is_admin)?_c('router-link',{attrs:{"to":{ name: 'client.materials' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"las la-cookie menu-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-8"},[_vm._v("Materials")])])])]}}],null,false,4609075)}):_vm._e(),(_vm.currentUser.u.is_admin)?_c('router-link',{attrs:{"to":{ name: 'client.suppliers' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"las la-cookie menu-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-8"},[_vm._v("Suppliers")])])])]}}],null,false,2247529868)}):_vm._e(),_c('router-link',{attrs:{"to":{
            name: 'client.changepassword',
          }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon las la-key"}),_c('span',{staticClass:"menu-text ml-8"},[_vm._v("Change Password")])])])]}}])}),(_vm.currentUser.u.is_admin)?_c('router-link',{attrs:{"to":{ name: 'client.user' }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link p-3 ml-5",staticStyle:{"color":"black"},attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"flaticon-user menu-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-8"},[_vm._v("Users")])])])]}}],null,false,1242802619)}):_vm._e()],1)],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-link"},[_c('i',{staticClass:"la la-stream menu-icon ml-2",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-10"},[_vm._v(" Quality Control ")]),_c('span',{staticClass:"when-opened float-right"},[_c('i',{staticClass:"fa fa-chevron-up"})]),_c('span',{staticClass:"when-closed float-right"},[_c('i',{staticClass:"fa fa-chevron-down"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-link"},[_c('i',{staticClass:"la la-stream menu-icon ml-2",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"menu-text ml-10"},[_vm._v(" Settings ")]),_c('span',{staticClass:"when-opened float-right"},[_c('i',{staticClass:"fa fa-chevron-up"})]),_c('span',{staticClass:"when-closed float-right"},[_c('i',{staticClass:"fa fa-chevron-down"})])])
}]

export { render, staticRenderFns }