<style lang="scss" scoped>
.badge {
  margin-left: 5%;
}
.collapsed > .menu-link > .when-opened,
.not-collapsed > .menu-link > .when-closed {
  display: none;
}
</style>
<template>
  <ul class="menu-nav">
    <router-link
      v-if="
        (getUserPermission.includes('oven_report') &&
          !currentUser.u.is_admin) ||
        currentUser.u.is_admin
      "
      :to="{
        name: 'client.dailyovenreports',
      }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon las la-calendar"></i>
          <span class="menu-text" style="color: black">Daily Oven Report </span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="
        (getUserPermission.includes('mixing_report') &&
          !currentUser.u.is_admin) ||
        currentUser.u.is_admin
      "
      :to="{
        name: 'client.dailymixing',
      }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon las la-mortar-pestle"></i>
          <span class="menu-text" style="color: black"
            >Daily Mixing Report
          </span>
        </a>
      </li>
    </router-link>
    <router-link
      v-if="
        (getUserPermission.includes('visitor_log') &&
          !currentUser.u.is_admin) ||
        currentUser.u.is_admin
      "
      :to="{
        name: 'client.visitorlogs',
      }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon las la-users"></i>
          <span class="menu-text" style="color: black">Visitor Logs </span>
        </a>
      </li>
    </router-link>
    <div
      class="accordion"
      role="tablist"
      v-if="
        ((getUserPermission.includes('packing_material') ||
          getUserPermission.includes('finished_goods') ||
          getUserPermission.includes('raw_materials')) &&
          !currentUser.u.is_admin) ||
        currentUser.u.is_admin
      "
    >
      <div no-body class="mb-1">
        <div header-tag="header" role="tab">
          <div
            class="menu-item p-5 mb-1"
            style="background: rgb(188, 222, 251, 0.5)"
            v-b-toggle.accordion-1
          >
            <div class="menu-link">
              <i class="la la-stream menu-icon ml-2" aria-hidden="true"></i>
              <span class="menu-text ml-10"> Quality Control </span>
              <span class="when-opened float-right">
                <i class="fa fa-chevron-up"></i>
              </span>
              <span class="when-closed float-right">
                <i class="fa fa-chevron-down"></i>
              </span>
            </div>
          </div>
        </div>
        <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
          <router-link
            v-if="
              (getUserPermission.includes('raw_material') &&
                !currentUser.u.is_admin) ||
              currentUser.u.is_admin
            "
            :to="{ name: 'client.qualitycontrol.rawmaterials' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="las la-luggage-cart menu-icon" aria-hidden="true"></i>
                <span class="menu-text ml-8">Raw materials</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="
              (getUserPermission.includes('packing_material') &&
                !currentUser.u.is_admin) ||
              currentUser.u.is_admin
            "
            :to="{ name: 'client.qualitycontrol.packingmaterials' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <!-- <i class="menu-icon flaticon2-time"></i> -->
                <i class="las la-box-open menu-icon" aria-hidden="true"></i>
                <span class="menu-text ml-8">Packing materials</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="
              (getUserPermission.includes('finished_goods') &&
                !currentUser.u.is_admin) ||
              currentUser.u.is_admin
            "
            :to="{ name: 'client.qualitycontrol.finishedgoods' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <!-- <i class="menu-icon flaticon2-time"></i> -->
                <!-- <i class="las la-tasks"></i> -->
                <i class="las la-tasks menu-icon" aria-hidden="true"></i>
                <span class="menu-text ml-8">Finished Goods</span>
              </a>
            </li>
          </router-link>
        </b-collapse>
      </div>
    </div>
    <div class="accordion" role="tablist">
      <div no-body class="mb-1">
        <div header-tag="header" role="tab">
          <div
            class="menu-item p-5 mb-1"
            style="background: rgb(188, 222, 251, 0.5)"
            v-b-toggle.accordion-2
          >
            <div class="menu-link">
              <i class="la la-stream menu-icon ml-2" aria-hidden="true"></i>
              <span class="menu-text ml-10"> Settings </span>
              <span class="when-opened float-right">
                <i class="fa fa-chevron-up"></i>
              </span>
              <span class="when-closed float-right">
                <i class="fa fa-chevron-down"></i>
              </span>
            </div>
          </div>
        </div>
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
          <router-link
            v-if="currentUser.u.is_admin"
            :to="{ name: 'client.biscuits' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="las la-cookie menu-icon" aria-hidden="true"></i>
                <span class="menu-text ml-8">Biscuits</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="currentUser.u.is_admin"
            :to="{ name: 'client.materials' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="las la-cookie menu-icon" aria-hidden="true"></i>
                <span class="menu-text ml-8">Materials</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="currentUser.u.is_admin"
            :to="{ name: 'client.suppliers' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="las la-cookie menu-icon" aria-hidden="true"></i>
                <span class="menu-text ml-8">Suppliers</span>
              </a>
            </li>
          </router-link>
          <router-link
            :to="{
              name: 'client.changepassword',
            }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <i class="menu-icon las la-key"></i>
                <span class="menu-text ml-8">Change Password</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="currentUser.u.is_admin"
            :to="{ name: 'client.user' }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a
                :href="href"
                class="menu-link p-3 ml-5"
                style="color: black"
                @click="navigate"
              >
                <!-- <i class="menu-icon flaticon2-time"></i> -->
                <i class="flaticon-user menu-icon" aria-hidden="true"></i>
                <span class="menu-text ml-8">Users</span>
              </a>
            </li>
          </router-link>
        </b-collapse>
      </div>
    </div>
  </ul>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import lodash from "lodash";
export default {
  name: "KTMenu",
  data() {
    return {
      show: true,
      client_slug: "",
      client: "",
      year: moment(String(new Date())).format("YYYY"),
      month: moment(String(new Date())).format("MM"),
      permissions: {
        you: ["access_to_own_attendance_records", "access_to_own_leaves_list"],
        company: [
          "access_to_all_employees_profile_data",
          "access_to_assign_shift",
          "access_to_all_leaves_list",
          "access_to_all_employees_attendance_records",
        ],
        payroll: ["access_to_salary_heads"],
        settings: ["access_to_settings"],
      },
    };
  },
  computed: {
    ...mapGetters(["getUserPermission", "currentUser"]),
  },
  toggleNavbar() {
    this.show = !this.show;
  },

  // created() {
  //   this.client_slug = this.$route.params.client_slug;
  //   this.client = this.currentUser.u.is_client ? "c" : "sp";
  // },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    checkPermissionForMenu(permission, permissions) {
      var flag = false;
      lodash.filter(permissions, function (o) {
        if (o == permission) {
          flag = true;
          return flag;
        }
        return flag;
      });
      return flag;
    },
    checkPermissionForMenuHeader(cPermissions, permissions) {
      let result = permissions.filter((o1) =>
        cPermissions.some((o2) => o1 === o2)
      );
      return result.length > 0;
    },
  },
};
</script>
