<style lang="scss">
.form-body {
  .card-body {
    padding: 20px;
  }
  .card-footer {
    padding: 15px 10px 15px 10px !important;
    position: sticky;
    bottom: 0;
  }
}
</style>
<template>
  <div class="d-flex flex-column flex-root" v-if="isAuthenticated">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <KTAside v-if="asideEnabled"></KTAside>
      <!-- end:: Aside Left -->

      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid"
        >
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <KTSubheader
            v-if="subheaderDisplay"
            v-bind:breadcrumbs="getBreadcrumbs"
          />
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div class="container-fluid" style="width: 95% !important">
              <transition name="fade-in-up">
                {{ layoutConfig }}
                <router-view />
              </transition>
            </div>
          </div>
        </div>

        <!-- <permission-denied v-else></permission-denied> -->
      </div>
    </div>

    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import lodash from "lodash";
import KTAside from "@/includes/aside/Aside.vue";
import KTHeader from "@/includes/headerclient/Header.vue";
import KTHeaderMobile from "@/includes/header/HeaderMobile.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTSubheader from "@/includes/subheader/Subheader.vue";
import KTScrollTop from "@/includes/extras/ScrollTop";


// import { SET_INTERVAL } from "@/core/services/store/mutations.type";

import Loader from "@/includes/content/Loader.vue";
// import PermissionDenied from "@/includes/content/PermissionDenied.vue";
// import {

//   REMOVE_BODY_CLASSNAME
// } from "@/core/services/store/modules/htmlclass.module.js";

export default {
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTSubheader,
    KTScrollTop,
    Loader,

    // "permission-denied": PermissionDenied
  },
  beforeMount() {
    // show page loading
    //this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    //console.log(this.layoutConfig);
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }

    // Simulate the delay page loading
    // setTimeout(() => {
    //   // Remove page loader after some time
    //   this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    // }, 2000);
  },
  methods: {
    checkPermissionForMenu(permissions) {
      var permission = this.$route.meta.permission;
      var flag = false;
      lodash.filter(permissions, function (o) {
        if (o == permission) {
          flag = true;
          return flag;
        }
        return flag;
      });
      return flag;
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "getBreadcrumbs",
      "pageTitle",
      "layoutConfig",
      "getUserPermission",
      "currentUser",
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
  },
};
</script>
